"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Header", {
  enumerable: true,
  get: function get() {
    return _Header["default"];
  }
});
Object.defineProperty(exports, "Footer", {
  enumerable: true,
  get: function get() {
    return _Footer["default"];
  }
});
Object.defineProperty(exports, "StatusBar", {
  enumerable: true,
  get: function get() {
    return _StatusBar["default"];
  }
});
Object.defineProperty(exports, "BASENAME", {
  enumerable: true,
  get: function get() {
    return _utils.BASENAME;
  }
});
Object.defineProperty(exports, "extractPowerType", {
  enumerable: true,
  get: function get() {
    return _utils.extractPowerType;
  }
});
Object.defineProperty(exports, "generateBaseURL", {
  enumerable: true,
  get: function get() {
    return _utils.generateBaseURL;
  }
});
Object.defineProperty(exports, "generateLegacyURL", {
  enumerable: true,
  get: function get() {
    return _utils.generateLegacyURL;
  }
});
Object.defineProperty(exports, "generateNewURL", {
  enumerable: true,
  get: function get() {
    return _utils.generateNewURL;
  }
});
Object.defineProperty(exports, "LEGACY_BASENAME", {
  enumerable: true,
  get: function get() {
    return _utils.LEGACY_BASENAME;
  }
});
Object.defineProperty(exports, "navigateToLegacy", {
  enumerable: true,
  get: function get() {
    return _utils.navigateToLegacy;
  }
});
Object.defineProperty(exports, "navigateToNew", {
  enumerable: true,
  get: function get() {
    return _utils.navigateToNew;
  }
});
Object.defineProperty(exports, "REACT_BASENAME", {
  enumerable: true,
  get: function get() {
    return _utils.REACT_BASENAME;
  }
});

var _Header = _interopRequireDefault(require("./components/Header"));

var _Footer = _interopRequireDefault(require("./components/Footer"));

var _StatusBar = _interopRequireDefault(require("./components/StatusBar"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }